import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import TeaserShortener from '../../Elements/Teaser/TeaserShortener';
import {
	TeaserStyled,
	TeaserImageStyled,
	TeaserLabelStyled,
	TeaserTitleStyled,
	TeaserContentStyled,
	TeaserImageWrapperStyled,
} from './styles';
import ImpressionClickTrackerHOC from '../ImpressionClickTracker/ImpressionClickTracker';

const DEFAULT = 'default';

const DossierTeaser = (props) => {
	const {item, position} = props;
	const appearance = item.appearance || DEFAULT;
	const imageProps = {type: 'teaser', appearance};
	const teaserProps = {
		appearance,
		title: item.title,
		label: item.label,
		slug: item.link.url,
		like: item.like,
		wordpressId: item.wordpressId,
		isSearchResult: item.isSearchResult,
	};

	const itemTitle = item.title.replace(/(<([^>]+)>)/gi, '');

	return (
		<Link to={`${item.link.url}`}>
			<ImpressionClickTrackerHOC>
				<TeaserStyled
					data-teaser-title={itemTitle}
					data-teaser-link={item.link.url}
					data-teaser-type="DossierTeaser"
					data-teaser-label={item.label}
					data-teaser-position={position}
					className="ap-teaser"
					{...props}
					{...teaserProps}>
					<TeaserImageWrapperStyled {...imageProps}>
						<TeaserImageStyled
							src={item.image.localFile.publicURL}
							alt={item.image.alt_text}
							title={item.image.title}
							{...imageProps}
						/>
						<TeaserContentStyled {...teaserProps}>
							<TeaserLabelStyled
								className="ap-article-teaser__label"
								{...teaserProps}
								text={item.label}
							/>
							<TeaserTitleStyled className="ap-article-teaser__title" {...teaserProps}>
								<TeaserShortener text={itemTitle} lines="3" />
							</TeaserTitleStyled>
						</TeaserContentStyled>
					</TeaserImageWrapperStyled>
				</TeaserStyled>
			</ImpressionClickTrackerHOC>
		</Link>
	);
};

DossierTeaser.propTypes = {
	position: PropTypes.number,
	item: PropTypes.shape({
		appearance: PropTypes.oneOf(['', DEFAULT, 'full-image', 'search']), // we allow '', but replace it with DEFAULT
		label: PropTypes.string,
		title: PropTypes.string,
		type: PropTypes.any,
		like: PropTypes.any,
		isSearchResult: PropTypes.any,
		wordpressId: PropTypes.any,
		text: PropTypes.string,
		onClick: PropTypes.func,
		link: PropTypes.shape({
			title: PropTypes.string,
			url: PropTypes.string.isRequired,
			target: PropTypes.string,
		}),
		image: PropTypes.shape({
			source_url: PropTypes.string,
			alt_text: PropTypes.string,
			title: PropTypes.string,
			localFile: PropTypes.shape({
				publicURL: PropTypes.PropTypes.shape({
					srcSet: PropTypes.string,
					src: PropTypes.string,
				}).isRequired,
			}),
		}),
	}),
};

DossierTeaser.defaultProps = {
	position: null,
	item: {
		appearance: DEFAULT,
		type: undefined,
		like: undefined,
		isSearchResult: undefined,
		wordpressId: undefined,
		label: '',
		title: '',
		text: '',
		onClick: null,
		link: null,
		image: null,
	},
};

/** @component */
export default DossierTeaser;
